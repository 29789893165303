/* eslint-disable @next/next/no-img-element */
import { css } from "@emotion/css";
import { FC } from "react";
import { mediaQuery } from "../../../utils/ui";
import { labels } from "../../../utils/ui/tokyoTop";
import { useContext } from "react";
import { DisplayContext } from "../../../utils/ui";
import { getImgSrc, getOverallColor } from "../../../utils/municipality";

type Props = {
  onclick: Function;
};
const altText = "NextPagebotton";

export const NextPagebotton: FC<Props> = (props) => {
  const currentDisplay = useContext(DisplayContext);
  return (
    <div className={boxStyle} onClick={() => props.onclick()}>
      <span className={textStyle}>{labels.addToHomeScreen}</span>
      <img
        src={getImgSrc("Tutorial-MoreActionNext")}
        alt={altText}
        width={16}
        height={16}
        className={imageStyle}
      />
    </div>
  );
};

const boxStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  text-align: left;
  color: #01b64e;
`;

const textStyle = css`
  color: ${getOverallColor("mainColor")};
  font-weight: 600;
`;

const imageStyle = css`
  display: inline-block;
  width: 9px;
  height: 16px;
  margin-left: 6px;
`;
