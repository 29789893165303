/* eslint-disable @next/next/no-img-element */
import { css } from "@emotion/css";
import { FC } from "react";
import { mediaQuery } from "../../../utils/ui";
import { getImgSrc } from "../../../utils/municipality";

const altText = "action";

const boxStyle = css`
  width: 36px;
  height: 36px;
`;

const imgStyle = css`
  width: 36px;
  height: 36px;
`;

export const ActionIcon: FC<{}> = () => {
  return (
    <div className={boxStyle}>
      <img
        src={`${getImgSrc("BingoTop-ActionIcon")}?${
          process.env.NEXT_PUBLIC_IMAGE_PARAMETERS
        }`}
        alt={altText}
        width={42}
        height={42}
        className={imgStyle}
      />
    </div>
  );
};
