import { BingoCard } from "../../services/cms/greenAction/types";
import { GetUserActionsByAuth0UserIdQuery } from "../../services/graphql/enhanceApi";

export type BingoActionStatus = {
  actionId: string;
  isDone: boolean;
};

type BingoCardIndex = {
  position: keyof BingoCard;
};
/**
 * CMSのビンゴ情報を指定順の配列に並び変えるためのindex定義
 */
export const bingoCardIndexes: Array<BingoCardIndex> = [
  { position: "upperLeft" },
  { position: "upperMiddle" },
  { position: "upperRight" },
  { position: "middleLeft" },
  { position: "middle" },
  { position: "middleRight" },
  { position: "lowerLeft" },
  { position: "lowerMiddle" },
  { position: "lowerRight" },
];
/**
 * CMS・graphqlレスポンスをbingo回数計数用のオブジェクトに整形する
 * @param bingoCards
 * @param currentStatus
 * @returns
 */
export const createBingoActionStatus = (
  bingoCards?: Array<BingoCard>,
  currentStatus?: GetUserActionsByAuth0UserIdQuery
) => {
  if (!bingoCards || bingoCards.length < 1 || !currentStatus) return undefined;

  return bingoCards.map<Array<BingoActionStatus>>((bingoCard) => {
    return bingoCardIndexes.map<BingoActionStatus>((bingoIndex) => {
      const bingoItem = bingoCard[bingoIndex.position];
      const isDone = !!currentStatus.zerocame_bingo_user_actions.find(
        (userAction) => userAction.action_id === bingoItem.id
      );
      return { actionId: bingoItem.id, isDone };
    });
  });
};
/**
 * ビンゴの成立条件を定義
 */
const bingoLines = [
  [0, 1, 2], // 横上
  [3, 4, 5], // 横中
  [6, 7, 8], // 横下
  [0, 3, 6], // 縦左
  [1, 4, 7], // 縦中
  [2, 5, 8], // 縦右
  [0, 4, 8], // ななめ
  [2, 4, 6], // ななめ
];

export type CountBingoResult = {
  count: number;
  doneBingopatterns?: boolean[][]; // 各ビンゴカードで達成しているビンゴパターンの配列、のビンゴカード数分の配列
};

/**
 * ビンゴ回数を産出する
 * @param bingoCardStatuses
 * @returns CountBingoResult
 */
export const countBingo = (
  bingoCardStatuses: BingoActionStatus[][]
): CountBingoResult => {
  if (bingoCardIndexes.length < 1) throw new Error("invalid parameter");
  // let bingoCount = 0;
  const returnValue: CountBingoResult = {
    count: 0,
    doneBingopatterns: bingoCardStatuses.map(() => [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ]),
  };

  bingoCardStatuses.forEach((bingoCardStatus, cardIndex) => {
    if (bingoCardStatus.length !== bingoCardIndexes.length)
      // ビンゴデータが不正（９個ではない）
      throw new Error("invalid parameter");
    // １つもアクションが実施されていない場合、チェックしない
    if (!bingoCardStatus.find((bingoItem) => bingoItem.isDone)) return;
    bingoLines.forEach((bingoLine, patternIndex) => {
      if (
        bingoCardStatus[bingoLine[0]].isDone &&
        bingoCardStatus[bingoLine[1]].isDone &&
        bingoCardStatus[bingoLine[2]].isDone
      ) {
        // bingoCount++;
        returnValue.count++;
        if (
          returnValue.doneBingopatterns &&
          returnValue.doneBingopatterns.length > cardIndex
        )
          // 達成されているビンゴパターンをtrueに設定する
          returnValue.doneBingopatterns[cardIndex][patternIndex] = true;
      }
    });
  });
  // return bingoCount;
  return returnValue;
};
