import { useEffect, useMemo, useState } from "react";
import { BingoCard } from "../../services/cms/greenAction/types";
import { GetUserActionsByAuth0UserIdQuery } from "../../services/graphql/enhanceApi";
import { useGreenActionState } from "../../services/greenAction/selectors";
import { updateBingoInfo } from "../../services/greenAction/slice";
import { useAppDispatch } from "../../services/store";
import {
  countBingo,
  CountBingoResult,
  createBingoActionStatus,
} from "../bingo";
import culcPointCount, { PointGetInfo } from "../bingo/point";

type ReturnType = {
  currentBingoCount: number; // 実行時に算出したビンゴ回数
  currentPointInfo: PointGetInfo; // 実行時に算出したバッジ獲得情報
  isUpdatedBingoCount?: boolean; // 前回算出時からビンゴ回数が増加している場合、true
};

export const useCountBingoAndPoint = (
  bingoCards?: Array<BingoCard>,
  currentStatus?: GetUserActionsByAuth0UserIdQuery
): ReturnType => {
  const dispatch = useAppDispatch();
  // storeに保存済みの値を取得する
  const { hasDoneActionAfterStartup, bingoCount } = useGreenActionState();

  // 外部APIから取得した計算用に整形する
  const bingoActionStatusArray = useMemo(() => {
    return createBingoActionStatus(bingoCards, currentStatus);
  }, [bingoCards, currentStatus]);
  // ビンゴ回数を算出する
  const { count: currentBingoCount } = useMemo((): CountBingoResult => {
    if (!bingoActionStatusArray) return { count: 0 };
    return countBingo(bingoActionStatusArray);
  }, [bingoActionStatusArray]);
  // バッジ獲得情報を算出する
  const currentPointInfo = useMemo(() => {
    return culcPointCount(currentBingoCount);
  }, [currentBingoCount]);

  const [updateInfo, setUpdateInfo] = useState<{
    isUpdatedBingoCount: boolean;
  }>({ isUpdatedBingoCount: false });
  useEffect(() => {
    if (bingoCount == undefined) {
      // ビンゴ情報初期化
      dispatch(
        updateBingoInfo({
          bingoCount: currentBingoCount,
        })
      );
    }
    // 前回算出時のビンゴ・バッジ情報を現在値と比較する
    else if (currentBingoCount > bingoCount) {
      // ビンゴ達成
      dispatch(
        updateBingoInfo({
          bingoCount: currentBingoCount,
        })
      );
      // ↑アプリ起動直後、前回までのビンゴ数、バッジ情報が算出された場合は値の更新のみ実施
      if (hasDoneActionAfterStartup) {
        // 前回算出時から増加した情報をstateに保持
        setUpdateInfo({
          isUpdatedBingoCount: true,
        });
      }
    }
  }, [bingoCount, dispatch, currentBingoCount, hasDoneActionAfterStartup]);
  // 算出した現在値のみ返却
  return {
    currentBingoCount: currentBingoCount,
    currentPointInfo,
    isUpdatedBingoCount: updateInfo.isUpdatedBingoCount,
  };
};
