import { css } from "@emotion/css";
import { CSSProperties, FC } from "react";
import { mediaQuery } from "../../../utils/ui";
import { AppointmentTabletDetailImg } from "../../atoms/AppointmentTabletDetailImg";
import { SeeDetailsButton } from "../../atoms/seeDetailsButton";
import { CloseButton } from "../../atoms/Close";
import { AppointmentTabletText } from "../../atoms/AppointmentTabletText";
import { labels } from "../../../utils/ui/tokyoTop";
import Lottie from "lottie-react";
import BackGroundPc_bg from "./BackGroundPc_bg.json";
import BackGroundSp_bg from "./BackGroundSp_bg.json";
import { useContext } from "react";
import { DisplayContext } from "../../../utils/ui";

type Props = {
  closeHandler: () => void;
  school?: string;
  schoolYear?: string;
  name: string;
};

export const AppointmentTablet: FC<Props> = (props) => {
  const currentDisplay = useContext(DisplayContext);
  const animationData =
    currentDisplay === "TAB_LANDSCAPE" ? BackGroundPc_bg : BackGroundSp_bg;
  const animationStyle: CSSProperties = {
    position: "fixed",
    width: "100vw",
    top: "0px",
    left: "0px",
    zIndex: "45",
  };
  return (
    <article className={boxStyle}>
      <Lottie
        animationData={animationData}
        loop={false}
        style={animationStyle}
      />
      <div className={modalContentsbaseStyle}>
        <div className={closeStyle}>
          <CloseButton closeModal={props.closeHandler} />
        </div>
        <div className={appointmentStyle}>
          <AppointmentTabletText
            title={labels.appointmentHeaders[0]}
            text={labels.appointmentHeaders[1]}
            exclamationMark={labels.appointmentHeaders[2]}
          />
        </div>
        <div className={DetailImgStyle}>
          <AppointmentTabletDetailImg
            school={props.school}
            width={599}
            height={424}
            schoolYear={props.schoolYear}
            name={props.name}
          />
        </div>
        <div className={spanStyle}>
          <span className={textStyle}>
            スクリーンショットをとって、保存しよう！
          </span>
        </div>
        <div className={doneStyle}>
          <SeeDetailsButton
            title={labels.closeButton}
            onclick={props.closeHandler}
            actionStatus="close"
          />
        </div>
      </div>
    </article>
  );
};

const modalContentsbaseStyle = css`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 51;
  padding-left: 22px;
  padding-right: 22px;
`;

const boxStyle = css`
  background-color: #fff;
  position: fixed;
  top: 0px;
  z-index: 50;
  width: 100vw;
  height: 100vh;
  padding-right: 22px;
  padding-left: 22px;
  overflow-y: scroll;
`;
const appointmentStyle = css`
  margin-top: 54px;
  margin-bottom: 33px;
`;

const textStyle = css`
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333;
`;

const spanStyle = css`
  text-align: center;
  margin-top: -35px;
  margin-bottom: 30px;
`;

const closeStyle = css`
  position: absolute;
  top: 8px;
  right: 8px;
  height: 56px;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const DetailImgStyle = css`
  padding-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
`;

const doneStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 56px;
`;
