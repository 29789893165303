/* eslint-disable @next/next/no-img-element */
import { css } from "@emotion/css";
import { FC } from "react";
import { mediaQuery } from "../../../utils/ui";
import { useContext } from "react";
import { DisplayContext } from "../../../utils/ui";
import { getImgSrc } from "../../../utils/municipality";

const altText = "ActionDetailTipsImage";

const imageStyle = css`
  width: 67px;
  height: 64px;
  margin-top: 67px;
`;
type Props = {
  onclick: Function;
};

export const AppointmentImg: FC<Props> = (Props) => {
  const currentDisplay = useContext(DisplayContext);
  return (
    <div onClick={() => Props.onclick()}>
      <img
        src={getImgSrc("Top-AppointmentImg")}
        alt={altText}
        width={72}
        height={72}
        className={imageStyle}
      />
    </div>
  );
};
