import { css } from "@emotion/css";
import { DisplayContext, mediaQuery } from "../../../utils/ui";

export const root = (backgroundImg: string, backgroundTbImg: string) => css`
  position: relative;
  width: 100vw;
  min-height: 100vh;
  background-image: url("${backgroundImg}");
  background-color: #fff;
  background-position: center top;
  background-size: 100% auto;
  background-repeat: no-repeat;
  ${mediaQuery.portrait} {
    width: auto;
    height: 100%;
    object-fit: cover;
    background-image: url("${backgroundTbImg}");
    background-repeat: no-repeat;
    background-size: cover;
  }
  ${mediaQuery.landscape} {
    width: 100vw;
    height: 100vh;
    background-image: url("${backgroundTbImg}");
    background-repeat: no-repeat;
    background-size: cover;
  }
`;

export const header = css`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 25;
`;
