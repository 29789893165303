import { css } from "@emotion/css";
import { FC } from "react";

const unit = "回";

type Props = {
  current: number;
  max: number;
};

export const BingoCountText: FC<Props> = ({ current, max }) => {
  return (
    <p className={boxStyle}>
      <span className={currentNumStyle}>{current}</span>
      <span className={currentUnitStyle}>{`${unit} / `}</span>
      <span className={maxNumStyle}>{max}</span>
      <span className={maxUnitStyle}>{unit}</span>
    </p>
  );
};

const boxStyle = css`
  margin-left: auto;
`;

const currentNumStyle = css`
  font-size: 28px;
  font-weight: 700;
  color: var(--base-black, #333);
  margin-right: 8px;
`;
const currentUnitStyle = css`
  font-size: 18px;
  font-weight: 800;
  color: var(--base-black, #333);
`;

const maxNumStyle = css`
  font-size: 20px;
  font-weight: 700;
  color: var(--base-black, #333);
`;
const maxUnitStyle = css`
  font-size: 14px;
  font-weight: 600;
  color: var(--base-black, #333);
`;
