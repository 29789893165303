import { css } from "@emotion/css";
import { FC } from "react";

export const CommonLoading: FC = () => {
  return (
    <article className={bodyStyle}>
      <div className={wrapper}>
        <div className={circle} />
        <div className={circle} />
        <div className={circle} />
        <div className={shadow} />
        <div className={shadow} />
        <div className={shadow} />
      </div>
    </article>
  );
};

const bodyStyle = css`
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  background: #fff;
`;

const wrapper = css`
  width: 200px;
  height: 60px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const circle = css`
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  background-color: #53cff0;
  left: 15%;
  transform-origin: 50%;
  animation: circle 0.5s alternate infinite ease;
  @keyframes circle {
    0% {
      top: 60px;
      height: 5px;
      border-radius: 50px 50px 25px 25px;
      transform: scaleX(1.7);
    }
    40% {
      height: 20px;
      border-radius: 50%;
      transform: scaleX(1);
    }
    100% {
      top: 0%;
    }
  }
  :nth-child(2) {
    left: 45%;
    animation-delay: 0.2s;
    background-color: #f9ff64;
  }
  :nth-child(3) {
    left: auto;
    right: 15%;
    animation-delay: 0.3s;
    background-color: #76feab;
  }
`;

const shadow = css`
  width: 20px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 62px;
  transform-origin: 50%;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: shadow 0.5s alternate infinite ease;
  @keyframes shadow {
    0% {
      transform: scaleX(1.5);
    }
    40% {
      transform: scaleX(1);
      opacity: 0.7;
    }
    100% {
      transform: scaleX(0.2);
      opacity: 0.4;
    }
  }
  :nth-child(4) {
    left: 45%;
    animation-delay: 0.2s;
  }
  :nth-child(5) {
    left: auto;
    right: 15%;
    animation-delay: 0.3s;
  }
`;
