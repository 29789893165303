import { GetNowQuery } from "../../services/graphql/enhanceApi";

/**
 * トップ画面背景切替用に、現在の日付とenvの日付を比較する
 * src\utils\actionPeriods\index.tsを流用
 *
 * @returns 期限内でtrue,期限外でfalseを返す
 */
// export const isInActionAvailablePeriods = ({
export const isBeforeBackgroundSwitch = ({
  zerocame_view_now: viewNow,
}: GetNowQuery): boolean => {
  // 引数が不正な場合は、有効期限内と判定する
  if (
    !viewNow ||
    viewNow.length < 1 ||
    !process.env.NEXT_PUBLIC_BACKGROUND_SWITCH_PERIOD_FROM_EPOCH
  )
    return true;
  const currentFromEpoch = Number(viewNow[0].epoch);
  const endPeriodFromEpoch = Number(
    process.env.NEXT_PUBLIC_BACKGROUND_SWITCH_PERIOD_FROM_EPOCH
  );
  if (
    typeof currentFromEpoch !== "number" ||
    Number.isNaN(currentFromEpoch) ||
    typeof endPeriodFromEpoch !== "number" ||
    Number.isNaN(endPeriodFromEpoch)
  ) {
    // 引数が不正な場合は、有効期限内と判定する
    return true;
  }

  return currentFromEpoch < endPeriodFromEpoch;
};
