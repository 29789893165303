import { useSelector } from "react-redux";
import { RootState } from "../store";
import { GreenActionState } from "./slice";

export const useGreenActionState = () => {
  return useSelector<RootState, GreenActionState>((state) => state.greenAction);
};

export const useGetActionCount = () => {
  return useSelector<RootState, number | undefined>(
    (state) => state.greenAction.actionCount
  );
};

export const useGetBingoCount = () => {
  return useSelector<RootState, number | undefined>(
    (state) => state.greenAction.bingoCount
  );
};
