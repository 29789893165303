import { css } from "@emotion/css";
import { FC } from "react";
import { mediaQuery } from "../../../utils/ui";
import { getOverallColor } from "../../../utils/municipality";

interface Props {
  title: string;
  onclick: Function;
}

export const TokyobuButton: FC<Props> = ({ title = "", onclick }) => {
  return (
    <div id="ga-top" className={buttonStyle} onClick={() => onclick()}>
      <span className={titleStyle}>{title}</span>
    </div>
  );
};

const titleStyle = css`
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
`;

export const buttonStyle = css`
  width: 311px;
  height: 44px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 8px;
  background: ${getOverallColor("mainColor")};
  box-shadow: 0px 13px 16px 0px ${getOverallColor("shadowColor")};
`;
