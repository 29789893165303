/* eslint-disable @next/next/no-img-element */
import { css } from "@emotion/css";
import { FC } from "react";
import { mediaQuery } from "../../../utils/ui";
import { labels } from "../../../utils/ui/tokyoTop";
import { getImgSrc, getLabel,getOverallHeight } from "../../../utils/municipality";

type Props = {
  name: string;
};

export const HomeEnvironment: FC<Props> = ({ name }) => {
  return (
    <>
      <div className={boxStyle}>
        <div className={pinStyle}>
          <img
            src={`${getImgSrc("Top-Pin")}`}
            alt={"pin"}
            width={22}
            height={22}
            // className={imageStyle}
          />
        </div>
        <div className={tytleBoxStyle}>
          <span className={titleStyle}>{getLabel("Top-NameTitle")}</span>
        </div>
        <span className={textStyle}>{`${name}${labels.san}`}</span>
      </div>
    </>
  );
};

const boxStyle = css`
  position: relative;
  height: ${getOverallHeight("nameHeight")};
  border-radius: 8px;
  box-shadow: -1px 1px 5px 0px #f3e5f0;
  background: var(--base-white, #fff);

  padding: 13px 10px 10px 10px;

  margin-top: 67px;
`;

const tytleBoxStyle = css`
  /* margin-bottom: 5px; */
`;

const titleStyle = css`
  font-size: 13px;
  line-height: 1.5;
  font-weight: 600;
`;

const pinStyle = css`
  position: absolute;
  top: -12px;
  left: 47%;
`;

const textStyle = css`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
`;
const maxNumStyle = css`
  margin-right: 3px;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: center;
`;
const maxUnitStyle = css`
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.89;
  letter-spacing: normal;
  text-align: center;
`;
