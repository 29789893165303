/* eslint-disable @next/next/no-img-element */
import { css } from "@emotion/css";
import { FC, useState } from "react";
import { TokyobuButton } from "../../atoms/TokyobuButton";
import { HomeEnvironment } from "../../atoms/HomeEnvironment";
import { NextPagebotton } from "../../atoms/NextPagebotton";
import { AppointmentDetailImg } from "../../molucules/AppointmentDetailImg";
import { AppointmentTablet } from "../AppointmentTablet";
import { AppointmentImg } from "../../atoms//AppointmentImg";
import { useContext } from "react";
import { DisplayContext } from "../../../utils/ui";
import { RewardHandlers } from "../../atoms/RewardsList";
import { ActionIcon } from "../../atoms/ActionIcon";
import { BingoCountText } from "../../atoms/BingoCountText";
import { BingoCountInfo } from "../../../utils/ui/tokyoTop";
import { getFlag, getImgSrc, getLabel, getUrl } from "../../../utils/municipality";
import * as style from "./style";

export type Props = {
  closeHandler: () => void;
  addHomeHandler: () => void;
  school?: string;
  schoolYear?: string;
  name: string;
  currentBingoCount: number;
  currentBingoInfo: BingoCountInfo;
  rewardHandlers: RewardHandlers;
  userCount: number;
  backgroundSwitch: boolean;
};

const doneButtonLabel = "ビンゴを開始する";

export const TokyobuTop: FC<Props> = (props) => {
  const getInner = () => {
    let width = window.innerWidth;
    let height = window.innerHeight;
    return [width, height];
  };
  const currentDisplay = useContext(DisplayContext);
  const backgroundImgPath = getBackgroundImgPath(
    currentDisplay,
    props.backgroundSwitch
  );
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <div className={background}>
        <div className={Style(backgroundImgPath)}>
          <div className={boxStyle(backgroundImgPath, props.backgroundSwitch)}>
            <div className={namePlateStyle}>
              <HomeEnvironment name={props.name} />
              {getFlag("Top-AppointmentEnable") 
              ? (
                <AppointmentImg
                  onclick={() => {
                    setModalOpen(true);
                  }}
                />
              )
              : (
                <AppointmentImg
                  onclick={() => {
                    window.open(process.env.NEXT_PUBLIC_FUJI_URL, "_blank", "noreferrer");
                  }}
                />
              )}
            </div>
            <div className={bingoTitleStyle}>
              <img
                src={getImgSrc("Top-ServiceLogo")}
                alt={"bingoTitle"}
                width={331}
                height={32}
                // className={bingoTitleStyle}
              />
            </div>
            <span className={bingoTitle}>
              {getLabel("Top-ServiceText")}
            </span>
            <div className={style.visualBlock}>
              <img
                src={props.currentBingoInfo.mainVisual.VisualImg}
                alt={props.currentBingoInfo.mainVisual.visualAlt}
                width={props.currentBingoInfo.mainVisual.width}
                height={props.currentBingoInfo.mainVisual.height}
              />
            </div>
            <section className={boxStyleSp}>
              <ActionIcon />
              <p className={textStyle}>ビンゴ回数</p>
              <BingoCountText current={props.currentBingoCount} max={24} />
            </section>
            <div className={doneStyle}>
              <TokyobuButton
                title={doneButtonLabel}
                onclick={props.closeHandler}
              />
            </div>
            <div
              className={`${style.nextBlock}${
                props.currentBingoInfo.isHidePresentLink ? " hide" : ""
              }`}
            >
              {getFlag("Top-GetPresentEnable") && (
                <NextPagebotton onclick={props.addHomeHandler} />
              )}
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <AppointmentTablet
          school={props.school}
          schoolYear={props.schoolYear}
          name={props.name}
          closeHandler={() => {
            setModalOpen(false);
          }}
        />
      )}
    </>
  );
};

/**
 * 背景に指定する画像ファイルパスを返却する.
 *
 * @param currentDisplay
 * @param backgroundSwitch
 * @returns
 */
const getBackgroundImgPath = (
  currentDisplay: string,
  backgroundSwitch: boolean
): string => {
  let backgroundImgPath = "";
  if (currentDisplay === "SP") {
    backgroundImgPath = backgroundSwitch
      ? "/img/phonebackGroundTokyoWinter.png"
      : "/img/phonebackGroundTokyo.png";
  } else if (currentDisplay === "TAB_PORTRAIT") {
    backgroundImgPath = backgroundSwitch
      ? "/img/AppointmentTabletTTImgWinter.png"
      : "/img/AppointmentTabletTTImg.png";
  } else {
    // 上記以外はタブレット横と判断する
    backgroundImgPath = backgroundSwitch
      ? "/img/backGroundTokyoWinter.png"
      : "/img/backGroundTokyo.png";
  }
  return backgroundImgPath + "?" + process.env.NEXT_PUBLIC_IMAGE_PARAMETERS;
};

export const backgroundWidth = 1024;
export const backgroundHeight = 768;

export const phonebackgroundWidth = 375;
export const phonebackgroundHeight = 667;

export const backgroundWidthLandscape = 768;
export const backgroundHeightLandscape = 1024;

const background = css`
  background-size: contain;
  z-index: 1;
`;

const Style = (backgroundImgPath: string) => css`
  top: 0;
  position: relative;
  background-size: cover;
  background-position: right bottom;
  /* height: 100svh; */
  margin-left: auto;
  margin-right: auto;
  padding: 0 22px 60px;
  z-index: 2;
`;

const boxStyle = (backgroundImgPath: string, backgroundSwitch: boolean) => css`
  margin-left: auto;
  margin-right: auto;
  width: 331px;
  left: calc(50% - ${phonebackgroundWidth}px / 2);
  z-index: 1;
`;

const bingoTitleStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`;

const AppointmentStyle = css`
  //position: absolute;
  top: 205px;
  left: 120px;
`;

const doneStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 311px;
  height: 44px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
`;

const bingoTitle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--base-black, #333);
  text-align: center;
  font-size: 15px;
  font-weight: 700;
  margin-top: 5px;
`;

const namePlateStyle = css`
  display: flex;
  justify-content: space-between;
`;

const boxStyleSp = css`
  display: flex;
  width: 331px;
  height: 60px;
  margin-top: 20px;
  background-color: #ffffff;
  padding: 12px 20px 12px 20px;
  vertical-align: top;
  margin: 0 auto 40px;
  border-radius: 8px;
`;

const textStyle = css`
  margin: 8px 0 0 15px;
  color: var(--base-black, #333);
  font-size: 16px;
  font-weight: 600;
`;
