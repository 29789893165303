import { getBingoPointThresholds } from "../municipality";

// ポイント獲得に必要なビンゴ数のリスト
export const pointGetArray: Array<number> = getBingoPointThresholds();
// ポイント獲得情報
export type PointGetInfo = {
  getCount: number;
  bingoCountUntilNext: number;
};

export const culcPointCount = (bingoCount: number): PointGetInfo => {
  // ポイント未獲得の場合
  const initValue = {
    getCount: 0,
    bingoCountUntilNext: pointGetArray[0] - bingoCount,
  };
  if (bingoCount < pointGetArray[0]) {
    return initValue;
  }
  // ポイントをすべて獲得している場合
  if (pointGetArray[pointGetArray.length - 1] <= bingoCount) {
    return {
      getCount: pointGetArray.length,
      bingoCountUntilNext: 0,
    };
  }

  let pointCount: PointGetInfo = initValue; // 論理的にinitValueのまま返却されることはない
  pointGetArray.some((badge, i) => {
    if (bingoCount == badge || bingoCount < pointGetArray[i + 1]) {
      pointCount = {
        getCount: i + 1,
        bingoCountUntilNext: pointGetArray[i + 1] - bingoCount, // 最後のポイント獲得は上でreturnされている想定
      };
      return true;
    }
  });
  return pointCount;
};

export default culcPointCount;
