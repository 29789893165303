/* eslint-disable @next/next/no-img-element */
import { css } from "@emotion/css";
import { FC } from "react";
import { mediaQuery } from "../../../utils/ui";
import { useContext } from "react";
import { DisplayContext } from "../../../utils/ui";

const imageStyle = css`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  ${mediaQuery.landscape} {
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
`;
const boxStyle = (isOverflow: boolean) => css`
  position: absolute;
  top: 34%;
  left: ${!isOverflow ? "0" : "-10px"};
  right: ${!isOverflow ? "0" : "-10px"};
  text-align: center;
  /* タブレット横向け */
  ${mediaQuery.landscape} {
    position: absolute;
    top: 33%;
    left: 0;
    right: 0;
    text-align: center;
  }
  ${mediaQuery.portrait} {
    position: absolute;
    top: 35%;
    left: 0;
    right: 0;
    text-align: center;
  }
`;

const schoolInfo = css`
  font-size: 6px;
  font-weight: 600;
  vertical-align: middle;
  margin-right: 12px;
  ${mediaQuery.portrait} {
    font-size: 9px;
    font-weight: 600;
  }
  ${mediaQuery.landscape} {
    font-size: 9px;
    font-weight: 600;
  }
`;

const userInfo = css`
  font-size: 12px;
  font-weight: 600;
  ${mediaQuery.portrait} {
    font-size: 18px;
    font-weight: 600;
  }
  ${mediaQuery.landscape} {
    font-size: 18px;
    font-weight: 600;
  }
`;

type Props = {
  school?: string;
  schoolYear?: string;
  name: string;
  width: number;
  height: number;
};

export const AppointmentTabletDetailImg: FC<Props> = (Props) => {
  const currentDisplay = useContext(DisplayContext);
  const isSpOverflow =
    currentDisplay == "SP" ||
    (!(Props.school === undefined) &&
      (Props.school.length > 30 || Props.name.length > 30));

  return (
    <div className={imageStyle}>
      {currentDisplay == "SP" && (
        <img
          src={`/img/AppointmentTabletDetailImg.png?${process.env.NEXT_PUBLIC_IMAGE_PARAMETERS}`}
          alt="AppointmentTabletDetailImg"
          width={375}
          className={imageStyle}
        />
      )}
      {currentDisplay == "TAB_LANDSCAPE" && (
        <img
          src={`/img/AppointmentTabletDetailImg.png?${process.env.NEXT_PUBLIC_IMAGE_PARAMETERS}`}
          alt="AppointmentTabletDetailImg"
          width={599}
          height={424}
          className={imageStyle}
        />
      )}
      {currentDisplay == "TAB_PORTRAIT" && (
        <img
          src={`/img/AppointmentTabletDetailImg.png?${process.env.NEXT_PUBLIC_IMAGE_PARAMETERS}`}
          alt="AppointmentTabletDetailImg"
          width={599}
          height={424}
          className={imageStyle}
        />
      )}
      <div className={boxStyle(isSpOverflow)}>
        <span className={schoolInfo}>
          {Props.school}&nbsp;{Props.schoolYear}
        </span>
        <span className={userInfo}>
          {Props.name}&nbsp;{"さん"}
        </span>
      </div>
    </div>
  );
};
