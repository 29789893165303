import { useRouter } from "next/router";
import { FC, useCallback, useEffect } from "react";
import { CommonLoading } from "../components/organisms/CommonLoading";
import { TokyoTop } from "../components/templates/TokyoTop";
import { useGetBingoCardsQuery } from "../services/cms/greenAction";
import {
  useBingoGetUserMunicipalityQuery,
  useGetUserActionsByAuth0UserIdQuery,
  useGetNowQuery,
} from "../services/graphql/enhanceApi";
import {
  ActionPeriodsContext,
  isInActionAvailablePeriods,
} from "../utils/actionPeriods";
import { isBeforeBackgroundSwitch } from "../utils/backgroundSwitchPeriods";
import { checkError } from "../utils/error";
import { useCountBingoAndPoint } from "../utils/hooks/useCountBingoAndBadge";
import { useErrorSetting } from "../utils/hooks/useErrorSetting";
import { useGreenActionAuth0 } from "../utils/hooks/useGreenActionAuth0";
import { useMenuHandlersCreator } from "../utils/hooks/useMenuHandlersCreator";
import { bingoCountInfo, BingoCountInfo } from "../utils/ui/tokyoTop";
import { path } from "../utils/ui/route";
import { getMunicipalityName } from "../utils/municipality";

/**
 * チュートリアル未実施の場合は、チュートリアルに遷移する
 * Auth0のユーザー情報を取得する
 * 最新のバッジ獲得情報を取得する
 * @returns
 */
const TopPage: FC<{}> = () => {
  const router = useRouter();
  const auth0State = useGreenActionAuth0();
  const isSkipApiCall =
    !auth0State.isAccessTokenReady || !auth0State.auth0UserId;
  // ビンゴカード情報をCMSから取得
  const {
    data: bingoCards,
    isSuccess: isCmsSuccess,
    error: cmsError,
  } = useGetBingoCardsQuery(undefined, { skip: isSkipApiCall });
  // graphQLからデータを取得
  const {
    data: userActions,
    isSuccess: isGraphqlSuccess,
    error: graphqlError,
  } = useGetUserActionsByAuth0UserIdQuery(
    {
      auth0_user_id: auth0State.auth0UserId as string,
      municipality: getMunicipalityName(),
    },
    { skip: isSkipApiCall } // アクセストークン取得済の場合のみリクエスト
  );

  //現在日時取得
  const {
    data: viewNows,
    isSuccess: isGetViewNowSuccess,
    error: getNowError,
  } = useGetNowQuery(
    {},
    { skip: !auth0State.isAccessTokenReady, refetchOnMountOrArgChange: true } // アクセストークン取得済の場合のみリクエスト
  );

  // userテーブルを検索
  const {
    data,
    isSuccess,
    error: getUsersError,
  } = useBingoGetUserMunicipalityQuery(
    {
      auth0_user_id: auth0State.auth0UserId as string,
      municipality: getMunicipalityName(),
    },
    { skip: !auth0State.isAccessTokenReady || !auth0State.auth0UserId }
  );

  //現在の総ユーザー数を取得
  // const {
  //   data: GetNowUsersCountQuery,
  //   isSuccess: isGetNowUsersSuccess,
  //   error: GetNowUsers,
  // } = useGetNowUserCountQuery(
  //   {},
  //   { skip: !auth0State.isAccessTokenReady, refetchOnMountOrArgChange: true } // アクセストークン取得済の場合のみリクエスト
  // );
  // エラー発生をチェック
  const errorState = checkError({
    auth0Error: auth0State.isError ? auth0State.error : undefined,
    graphqlErrors: [
      graphqlError
        ? { error: graphqlError, params: [auth0State.auth0UserId as string] }
        : undefined,
      getNowError ? { error: getNowError } : undefined,
      // GetNowUsers ? { error: GetNowUsers } : undefined,
    ],
    cmsErrors: [cmsError ? { error: cmsError } : undefined],
    auth0UserId: auth0State.auth0UserId,
  });
  // エラー制御
  useErrorSetting(errorState);

  // ビンゴ回数、バッジ獲得情報を算出
  const { currentBingoCount, currentPointInfo: currentBadgeInfo } =
    useCountBingoAndPoint(bingoCards?.contents, userActions);
  const currentBingoCountInfo = bingoCountInfo[currentBingoCount];
  const startAction = useCallback(() => {
    // TODO:仮でビンゴアクションTOP画面に遷移させている
    router.push(path.actionTop);
  }, [router]);

  const addHomeHandler = useCallback(() => {
    window.open(currentBingoCountInfo.presentUrl, "_blank", "noreferrer");
  }, [currentBingoCountInfo]);

  const menuHandlers = useMenuHandlersCreator();

  // リワード画像click時の遷移（コンプリート前）
  const seeRewardHandler = useCallback(() => {
    const seeRewardUrl = new URL(
      process.env["NEXT_PUBLIC_SEE_REWARD_URL"] || ""
    );
    window.open(seeRewardUrl.href, "_blank", "noreferrer");
  }, []);

  // リワード画像click時の遷移（コンプリート後）
  const applyRewardHandler = useCallback(() => {
    const applyRewardUrl = new URL(
      process.env["NEXT_PUBLIC_SEE_REWARD_COMPLETE_URL"] || ""
    );
    window.open(applyRewardUrl.href, "_blank", "noreferrer");
  }, []);

  useEffect(() => {
    /**
     * iOSバグ対応：
     * Auth0ログイン画面からブラウザバックした場合、Lodingが表示され続ける問題の対応
     */
    const reloadPage = () => {
      if (!auth0State.userMetadata || !isCmsSuccess || !isGraphqlSuccess) {
        window.location.reload();
      }
    };
    const reloadTimer = setTimeout(reloadPage, 5000);
    return clearTimeout(reloadTimer);
  }, [auth0State.userMetadata, isCmsSuccess, isGraphqlSuccess]);

  let isOutOfPeriods = false;
  let isBackgroundSwitch = false;
  if (isGetViewNowSuccess) {
    isOutOfPeriods = !isInActionAvailablePeriods(viewNows);
    isBackgroundSwitch = !isBeforeBackgroundSwitch(viewNows);
  }

  if (
    !auth0State.userMetadata ||
    !isCmsSuccess ||
    !isGraphqlSuccess ||
    !isSuccess
  )
    return <CommonLoading />;
  // TODO:テーブルが存在しないため固定値を設定
  // const nowUserCounts = GetNowUsersCountQuery?.greenapp_viewUsers[0].count;
  const nowUserCounts = 10;
  return (
    <ActionPeriodsContext.Provider value={{ isOutOfPeriods }}>
      <TokyoTop
        auth0UserMetaData={auth0State.userMetadata}
        userData={data}
        currentBingoCount={currentBingoCount}
        startActionHandler={startAction}
        currentBingoInfo={currentBingoCountInfo}
        addHomeHandler={addHomeHandler}
        menuHandlers={menuHandlers}
        rewardHandlers={{
          seeRewardHandler: seeRewardHandler,
          applyRewardHandler: applyRewardHandler,
        }}
        NowUserCount={nowUserCounts}
        backgroundSwitch={isBackgroundSwitch}
      />
    </ActionPeriodsContext.Provider>
  );
};

export default TopPage;
