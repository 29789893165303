import { FC, ReactNode } from "react";
import * as style from "./style";
import { Header, MenuHandlers } from "../../organisms/Header";
import { getBackgroundImgSrc, getImgSrc } from "../../../utils/municipality";

interface Props {
  menuHandlers: MenuHandlers;
  children: ReactNode;
}

export const TopBackground: FC<Props> = (props) => {
  return (
    <div className={style.root(getImgSrc("Top-Background"), getImgSrc("Top-Background-Tb"))}>
      <div className={style.header}>
        <Header menuHandlers={props.menuHandlers} />
      </div>
      <div>{props.children}</div>
    </div>
  );
};
