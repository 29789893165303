import { css } from "@emotion/css";

export const visualBlock = css`
  display: flex;
  margin-top: 38px;
  align-items: center;
  justify-content: center;
`;

export const nextBlock = css`
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;

  &.hide {
    visibility: hidden;
  }
`;
