import { FC } from "react";
import { UserMetadata } from "../../../utils/hooks/useGreenActionAuth0";
import { BingoGetUserMunicipalityQuery } from "../../../services/graphql/enhanceApi";
import { MenuHandlers } from "../../organisms/Header";
import { TokyobuTop } from "../../organisms/TokyobuTop";
import { RewardHandlers } from "../../atoms/RewardsList";
import { BingoCountInfo } from "../../../utils/ui/tokyoTop";
import { TopBackground } from "../TopBackground";

type Props = {
  auth0UserMetaData: UserMetadata;
  userData: BingoGetUserMunicipalityQuery;
  currentBingoCount: number;
  currentBingoInfo: BingoCountInfo;
  startActionHandler: () => void;
  addHomeHandler: () => void;
  menuHandlers: MenuHandlers;
  rewardHandlers: RewardHandlers;
  NowUserCount: number;
  backgroundSwitch: boolean;
};

export const TokyoTop: FC<Props> = (props) => {
  const name = props.userData.zerocame_users[0].bingo_users[0].name
    ? props.userData.zerocame_users[0].bingo_users[0].name
    : "";
  const school = props.userData.zerocame_users[0].bingo_users[0].school
    ? props.userData.zerocame_users[0].bingo_users[0].school
    : "";
  const schoolYear = props.userData.zerocame_users[0].bingo_users[0].grade
    ? props.userData.zerocame_users[0].bingo_users[0].grade
    : "";
  return (
    <TopBackground menuHandlers={props.menuHandlers}>
      <TokyobuTop
        closeHandler={props.startActionHandler}
        addHomeHandler={props.addHomeHandler}
        school={school}
        schoolYear={schoolYear}
        currentBingoInfo={props.currentBingoInfo}
        name={name}
        currentBingoCount={props.currentBingoCount}
        rewardHandlers={props.rewardHandlers}
        userCount={props.NowUserCount}
        backgroundSwitch={props.backgroundSwitch}
      />
    </TopBackground>
  );
};
