import { css } from "@emotion/css";
import { FC } from "react";
import { mediaQuery } from "../../../utils/ui";

type Props = {
  title: string;
  text: string;
  exclamationMark: string;
};

export const AppointmentTabletText: FC<Props> = ({
  title,
  text,
  exclamationMark,
}) => {
  return (
    <p className={boxStyle}>
      <span className={currentNumStyle}>{title}</span>
      <br />
      <span className={maxUnitStyle}>{text}</span>
      <span className={exclamationMarkStyle}>{exclamationMark}</span>
    </p>
  );
};

const boxStyle = css`
  //width: 450px;
  height: 109.5px;
  //margin: 0px 96.5px 26.8px 45px;
  margin-right: 30px;
  margin-top: 55px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  /* ${mediaQuery.portrait} {
    height: 109.5px;
  //margin: 0px 96.5px 26.8px 45px;
  margin-right : 30px;
  margin-top: 100px;
    height: 109.5px;
    margin: 0px 96.5px 26.8px 45px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  } */
  /* タブレット横向け */
  ${mediaQuery.landscape} {
    width: 450px;
    height: 109.5px;
    margin: 0px 96.5px 26.8px 45px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
`;

const currentNumStyle = css`
  width: 450px;
  height: 53px;
  margin: 0 0 56.5px;
  //font-family: HiraginoKakuGothicProN-W6;
  font-size: 27px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
  color: #16cb63;
  ${mediaQuery.portrait} {
    width: 450px;
    height: 53px;
    margin: 0 0 56.5px;
    //font-family: HiraginoKakuGothicProN-W6;
    font-size: 40px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: #16cb63;
  }
  ${mediaQuery.landscape} {
    width: 450px;
    height: 53px;
    margin: 0 0 56.5px;
    //font-family: HiraginoKakuGothicProN-W6;
    font-size: 40px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: #16cb63;
  }
`;

const maxUnitStyle = css`
  width: 428px;
  height: 53px;
  margin: 6.5px 0 2.5px 0;
  //font-family: HiraginoKakuGothicProN-W6;
  font-size: 27px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
  color: #16cb63;
  ${mediaQuery.portrait} {
    width: 420px;
    height: 53px;
    margin: 6.5px 0 2.5px 0;
    //font-family: HiraginoKakuGothicProN-W6;
    font-size: 40px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: #16cb63;
  }
  ${mediaQuery.landscape} {
    width: 420px;
    height: 53px;
    margin: 6.5px 0 2.5px 0;
    //font-family: HiraginoKakuGothicProN-W6;
    font-size: 40px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: #16cb63;
  }
`;

const exclamationMarkStyle = css`
  width: 22px;
  height: 43px;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.56;
  text-align: center;
  color: #16cb63;
  ${mediaQuery.portrait} {
    width: 30px;
    height: 53px;
    font-size: 46px;
    font-weight: 700;
    line-height: 1.22;
    text-align: center;
    color: #16cb63;
  }
  ${mediaQuery.landscape} {
    width: 30px;
    height: 55px;
    font-size: 46px;
    font-weight: 700;
    line-height: 1.22;
    text-align: center;
    color: #16cb63;
  }
`;
